<!--
 * @Description: 创建审批申请
 * @Author: ChenXueLin
 * @Date: 2021-10-21 11:07:11
 * @LastEditTime: 2022-01-12 17:25:09
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      :element-loading-background="loadingBackground"
    >
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <div class="detailInfo-content">
            <!-- 基本信息 start -->
            <div class="baseInfo-box">
              <div class="edit-title">开票信息</div>
              <el-form
                label-width="140px"
                label-position="right"
                :inline="true"
              >
                <el-form-item label="企业类型">
                  <e6-vr-select
                    v-model="searchForm.typeId"
                    :data="typeList"
                    placeholder="企业类型"
                    title="企业类型"
                    clearable
                  ></e6-vr-select>
                </el-form-item>
                <el-form-item label="开票名称">
                  <el-input placeholder="开票名称"></el-input>
                </el-form-item>
                <el-form-item label="备注">
                  <el-input placeholder="备注" maxlength="100"></el-input>
                </el-form-item>
              </el-form>
            </div>
            <!-- 基本信息 end -->
            <!-- 客户基础信息 start -->
            <div class="baseInfo-box">
              <div class="edit-title">客户基础信息</div>
              <el-form label-width="140px" :inline="true">
                <el-form-item label="客户名称">
                  <el-input placeholder="客户名称"></el-input>
                </el-form-item>
                <el-form-item label="客户属性">
                  <el-input placeholder="客户属性"></el-input>
                </el-form-item>

                <el-form-item label="客户来源">
                  <e6-vr-select
                    v-model="searchForm.typeId"
                    :data="typeList"
                    placeholder="客户来源"
                    title="客户来源"
                    clearable
                  ></e6-vr-select>
                </el-form-item>
                <el-form-item label="所属主体">
                  <e6-vr-select
                    v-model="searchForm.typeId"
                    :data="typeList"
                    placeholder="所属主体"
                    title="所属主体"
                    clearable
                  ></e6-vr-select>
                </el-form-item>
                <el-form-item label="注册资本">
                  <el-input placeholder="注册资本"></el-input>
                </el-form-item>
                <el-form-item label="业务员">
                  <e6-vr-select
                    v-model="searchForm.typeId"
                    :data="typeList"
                    placeholder="业务员"
                    title="业务员"
                    clearable
                  ></e6-vr-select>
                </el-form-item>
                <el-form-item label="客户地址">
                  <div class="address-box">
                    <e6-city
                      :format="['区县']"
                      :clearable="false"
                      :placeholder="'省/市/县'"
                    ></e6-city>
                    <el-input placeholder="详细地址"></el-input>
                  </div>
                </el-form-item>
              </el-form>
            </div>
            <!--客户基础信息 end -->
            <!-- 客户联系人 start -->
            <div class="baseInfo-box">
              <div class="edit-title">客户联系人</div>
              <customer-contact></customer-contact>
            </div>
            <!-- 客户联系人 end -->
          </div>
        </div>
        <div class="edit-wrapper__footer">
          <el-button class="cancel">取消</el-button>
          <el-button type="primary">确定</el-button>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";

import CustomerContact from "../components/customerContact.vue";

export default {
  name: "addAudit",
  components: { CustomerContact },
  data() {
    return {
      searchForm: {
        orderNo: "",
        typeId: "",
        secondType: "",
        pageIndex: 1,
        pageSize: 20
      },
      typeList: [
        {
          id: 1,
          label: "任务单"
        },
        {
          id: 2,
          label: "服务单"
        },
        {
          id: 3,
          label: "协同单"
        }
      ]
    };
  },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  watch: {},
  methods: {},
  created() {}
};
</script>
<style lang="scss" scoped>
.baseInfo-box {
  .el-input {
    width: 220px;
  }
  .el-select {
    width: 220px;
  }
}
</style>
